<template>
    <maintenance-page
        v-if="propertyId"
        :value="formValue"
        page-title="Property Area"
        page-icon="mdi-office-building"
        collection-name="propertyArea"
        :base-url="baseUrl"
        :done-url="doneUrl"
        :shadow-value-string="JSON.stringify(formValue)"
        @save="saveFormValue"
        @input="updateFormValue"
        :key="`${propertyId} ${propertyAreaId}`"
    >
        <template v-slot:after-form>
            <PropertyAreaList
                :property-id="propertyId"
                :property-area-id="$route.params.id"
                :base-url="baseUrl"
                :done-url="doneUrl"
                :key="JSON.stringify(savedFormValue)"
            />
        </template>
    </maintenance-page>
</template>

<script>
import MaintenancePage from '../../../components/pages/Maintenance';
import PropertyAreaList from '../../../views/admin/maintenance/PropertyAreaList';

export default {
    name: 'PropertyDetail',
    components: { MaintenancePage, PropertyAreaList },
    data: () => ({
        propertyId: null,
        propertyAreaId: null,
        formValue: null,
        savedFormValue: null,
        propertyAreaType: null,
    }),
    methods: {
        init() {
            this.propertyId = this.$route.params.propertyId;
            this.propertyAreaId = this.$route.params.id;
            console.log('PropertyAreaDetail init propertyID', this.propertyId, this.propertyAreaId);
        },
        saveFormValue(val) {
            this.savedFormValue = val;
        },
        updateFormValue(val) {
            this.formValue = val;
            this.propertyAreaType = val.propertyAreaType;
            console.log('running updateFormValue propertyareadetail', val, this.propertyAreaType)
        },
        logForm() {
            console.log('form updated', this.formValue)
        }
    },
    watch: {
        '$route.params.propertyId'(propertyId) {
            console.log('watched propertyAreaDetail', { propertyId });
            this.propertyId = propertyId;
        },
        '$route.params.id'(propertyAreaId) {
            console.log('watched propertyAreaDetail', { propertyAreaId });
            this.propertyAreaId = propertyAreaId;
        },
    },
    computed: {
        baseUrl() {
            return `property/${this.propertyId}/propertyarea`;
        },
        doneUrl() {
            return `property/${this.propertyId}`;
        },
    },
    updated() {
        this.formValue = null;
    },
    mounted() {
        this.init();
        console.log('mounted propertyAreaDetail', this.propertyId);
    },
};
</script>
